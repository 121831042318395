'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    okText: 'Ok',
    dismissText: 'Cancel',
    extra: 'please select'
};
module.exports = exports['default'];