'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    confirmLabel: 'Done',
    backspaceLabel: 'Backspace',
    cancelKeyboardLabel: 'CancelKeyboard'
};
module.exports = exports['default'];